<template>
  <div class="rank-component">
    <template v-for="(item,index) in list">
      <div :key="index">
        <span>{{index+1}}</span>
        <span>{{item.userName}}</span>
        <span>{{item.proNum}}</span>
      </div>
    </template>
  </div>
</template>

<script>
import { selectInputStatistics } from '@/api'
export default {
  // props: {
  //   startTime: {
  //     type: String,
  //     default: ""
  //   },
  //   endTime: {
  //     type: String,
  //     default: ""
  //   }
  // },
  data() {
    return {
      list: []
    }
  },
  mounted() {
    // this.selectInputStatistics()
  },
  methods: {
    async selectInputStatistics(value = ['', '']) {
      console.log(value);
      const params = {
        startTime: value[0] ? value[0]+' 00:00:00' : '',
        endTime: value[1] ? value[1]+' 23:59:59' : '',
        limit: 10
      }
      const { data } = await selectInputStatistics(params)
      if (data && data.code === 0) {
        this.list = data.data
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.rank-component > div:nth-child(1) > span {
  &:nth-child(1) {
    background: #ff5471;
    color: #fff;
  }
}
.rank-component > div:nth-child(2) > span {
  &:nth-child(1) {
    background: #f8a922;
    color: #fff;
  }
}
.rank-component > div:nth-child(3) > span {
  &:nth-child(1) {
    background: #4ea2ee;
    color: #fff;
  }
}
.rank-component div {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  span {
    &:nth-child(1) {
      background: #f6f6f6;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      opacity: 1;
      display: block;
      text-align: center;
      line-height: 20px;
      color: #333333;
      font-size: 12px;
    }
    &:nth-child(2) {
      flex: 1;
      margin-left: 20px;
      color: #000;
    }
    &:nth-child(3) {
      color: #666666;
    }
  }
}
</style>