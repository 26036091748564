<template>
  <div :class="className" :style="{ height: height, width: width }" />
</template>

<script>
import * as echarts from "echarts";
import "echarts-gl";
// require("echarts/theme/macarons"); // echarts theme
import resize from "./mixins/resize";

import { selCatDist } from "@/api";

export default {
  mixins: [resize],
  props: {
    className: {
      type: String,
      default: "chart",
    },
    width: {
      type: String,
      default: "100%",
    },
    height: {
      type: String,
      default: "410px",
    },
    chartData: {
      type: Array,
      default: () => []
    },
    chartIsLoad: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      chart: null,
    };
  },
  mounted() {
    if (this.chartIsLoad) {
      this.selCatDist();
    } else {
      this.initChart(this.chartData);
    }
  },
  beforeDestroy() {
    if (!this.chart) {
      return;
    }
    this.chart.dispose();
    this.chart = null;
  },
  methods: {
    initChart(data) {
      this.chart = echarts.init(this.$el, "macarons");

      let options = {
        tooltip: {
          trigger: "item",
          formatter: "{a} <br/>{b}: {c} ({d}%)",
        },
        grid: { 
          x: 10, 
          y: 10,    
          containLabel: true  
        } ,
        legend: {
          orient: "vertical",
          icon: "circle",
          data: data.map((item,index) => {
            if (index < 10) {
              return item.categoryName
            }
          }), //["直接访问", "邮件营销", "联盟广告", "视频广告", "搜索引擎"],
          x: "60%",
          y: "10%",
          width: "100",
          padding: [10, 10, 0, 0],
          itemWidth: 8, // 图形宽度。
          itemHeight: 8, // 图形高度。
          itemGap: 20, // 间隔

          formatter: function (name) {
            // console.log(name);
            let html = "";
            for (var i = 0, l = data.length; i < l; i++) {
              if (data[i].categoryName === name) {
                html += name + "  |  " + "{ts|" + data[i].proportion + "      " + data[i].count + "}";
                // html += name + "  |  " + "{ts|" + data[i].count + "}";
              }
            }
            return html;
          },
          textStyle: {
            color: '#000',
            rich: {
              ts: {
                color: '#999'
              }
            }
          }
        },

        series: [
          {
            // name: "产品分类分布",
            tooltip: { 
              show:true,
              trigger: 'item',
              showContent:true,
              formatter: '{b} | {d}% {c}'
            },
            type: "pie",
            center: ["30%", "50%"],
            radius: ["40%", "60%"],
            avoidLabelOverlap: false,
            label: {
              show: true,
              position: "center",
              formatter: function () {
                return (
                  "{tt|" + "产品总数\r\n\n " + "}" +
                  "{ta|" +
                  data.map((item) => item.count).reduce((x, y) => {
                    return x + y;
                  })
                  + "}"
                );
              },
              textStyle: {
                color: '#707070',
                rich: {
                  tt: {
                    color: '#707070',
                    fontSize: 16
                  },
                  ta: {
                    color: '#000',
                    fontSize: 36
                  }
                }
              }
            },
            emphasis: {
              label: {
                show: false,
                fontSize: "30",
                fontWeight: "bold",
                position: "center",
              },
            },
            labelLine: {
              show: false,
            },
            data: data.map((item) => {
              return {
                value: item.count,
                name: item.categoryName,
              };
            }),
            // [
            //   { value: 335, name: "直接访问" },
            //   { value: 310, name: "邮件营销" },
            //   { value: 234, name: "联盟广告" },
            //   { value: 135, name: "视频广告" },
            //   { value: 1548, name: "搜索引擎" },
            // ],
            itemStyle: {
              borderWidth: 5,
              borderColor: "#fff",
            },
          },
        ],
      };

      this.chart.setOption(options);
    },
    //首页--获取产品分类分布（modularType = 8）
    async selCatDist() {

      const { data } = await selCatDist();
      if (data && data.code === 0) {
        this.$nextTick(() => {
          this.initChart(data.data);
        });
        this.$emit('piechartemit',data.data)
      }
    },
  },
};
</script>
