import request from '@/utils/request'

// 
export function findBarValueDir (params) {
    return request({
        url: '/record/record/findBarValueDir',
        method: 'get',
        params: params
    })
}

// 色系饼图
export function findProductAttrValueDir (params) {
    return request({
        url: '/record/record/findProductAttrValueDir',
        method: 'get',
        params: params
    })
}

  // 色系饼图
export function findProductAttrDir (params) {
    return request({
        url: '/record/record/findProductAttrDir',
        method: 'get',
        params: params
    })
}

// 统计人员排行
export function selectCustomStatistics(params) {
    return request({
      url: '/record/record/selectCustomStatistics',
      method: 'get',
      params
    })
}

export function selectCustomInfoList (params) {
    return request({
      url: '/record/record/selectCustomInfoList',
      method: 'get',
      params: params
    })
}

export function getCustomInfoStatistics (params) {
    return request({
      url: '/record/record/getCustomInfoStatistics',
      method: 'get',
      params: params
    })
}

export function queryProduct() {
    return request({
      url: '/product/product/queryProduct',
      method: 'get'
    })
}

export function queryAddCount (params) {
    return request({
      url: '/record/record/queryAddCount',
      method: 'get',
      params: params
    })
}






