import request from '@/utils/request'

// 获取列表
export function list (params) {
  return request({
    url: '/productattr/productattr/list',
    method: 'get',
    params: params
  })
}
// 获取列表
export function getAttrAndValueListInfo (params) {
  return request({
    url: '/productattr/productattr/getAttrAndValueListInfo',
    method: 'get',
    params: params
  })
}

// 获取光泽参考值
export function getGlossInfo (params) {
  return request({
    url: '/productattr/productattr/getGlossInfo',
    method: 'get',
    params: params
  })
}
// 根据输入的值获取对应光泽属性值
export function selectGlossInfo (params) {
  return request({
    url: '/productattr/productattr/selectGlossInfo',
    method: 'get',
    params: params
  })
}

export function getAttrTableCols (params) {
  return request({
    url: '/productattr/productattr/getAttrTableCols',
    method: 'get',
    params: params
  })
}

// 获取信息
export function info (params) {
  return request({
    url: '/productattr/productattr/info',
    method: 'get',
    params: params
  })
}

// 添加
export function add (params) {
  return request({
    url: '/productattr/productattr/save',
    method: 'post',
    data: params
  })
}

// 修改
export function update (params) {
  return request({
    url: '/productattr/productattr/update',
    method: 'post',
    data: params
  })
}

// 删除
export function del (params) {
    return request({
        url: '/productattr/productattr/delete',
        method: 'post',
        data: params
    })
}

// 批量更新产品属性值表
export function productBatchUpdate (params) {
    return request({
        url: '/productattr/productattr/productBatchUpdate',
        method: 'post',
        data: params
    })
}

export function selectProductAttrList (params) {
  return request({
    url: '/productattr/productattr/selectProductAttrList',
    method: 'get',
    params: params
  })
}

export function selectBrandProductAttrList (params) {
  return request({
    url: '/productattr/productattr/selectBrandProductAttrList',
    method: 'get',
    params: params
  })
}

export function synProductAttr (params) {
  return request({
    url: '/productattr/productattr/synProductAttr',
    method: 'post',
    data: params
  })
}

export function selectSynProductAttrState (params) {
  return request({
    url: '/productattr/productattr/selectSynProductAttrState',
    method: 'get',
    params: params
  })
}