<template>
  <el-card class="box-card-component">
    <div slot="header">
      {{ title }}
    </div>
    <div class="main" v-if="title==='产品概览'">
      <div>
        <template v-for="(item,index) in proOverview">
          <span class="pro" :key="index" :style="{ width:(100/proOverview.length)+'%' }">{{item}}<i class="el-icon-caret-right"></i></span>
        </template>
      </div>
      <div>
        <template v-for="(item,index) in proList">
          <span class="pro-color" :key="index" :style="{ width:(100/proOverview.length)+'%' }">{{item}}</span>
        </template>
      </div>
    </div>
    <div class="main" v-else-if="title==='设备概览'">
      <div>
        <template v-for="(item,index) in devOverview">
          <span class="dev-center" :key="index" :style="{ width:(100/devOverview.length)+'%' }">{{item}}</span>
        </template>
      </div>
      <div>
        <template v-for="(item,index) in devList">
          <span class="dev-center" :key="index" :style="{ width:(100/devOverview.length)+'%' }">{{item}}</span>
        </template>
      </div>
    </div>
    <div class="main" v-else-if="title==='搜索概览'">
      <div>
        <template v-for="(item,index) in serOverview">
          <span class="ser" :key="index" :style="{ width:(100/serOverview.length)+'%',margin:'0px 0px 0px 10px' }">{{item}}<i class="el-icon-caret-right"></i></span>
        </template>
      </div>
      <div>
        <!-- <template v-for="(item,index) in serList">
          <span class="ser-color" :key="index" :style="{ width:(100/serOverview.length)+'%' }">{{item}}</span>
        </template> -->
        <template>
          <span class="ser" :style="{ width:(100/serOverview_.length)+'%' }">{{serList ? serList['searchCount'] : '0'}}</span>
        </template>
        <template>
          <span class="ser" :style="{ width:(100/serOverview_.length)+'%' }">{{serList ? serList['handleCount'] : '0'}}</span>
        </template>
        <template>
          <span class="ser" :style="{ width:(100/serOverview_.length)+'%' }">{{serList ? serList['noHandleCount'] : '0'}}</span>
        </template>
        <template>
          <span class="ser" :style="{ width:(100/serOverview_.length)+'%' }">{{serList ? serList['searchPersonCount'] : '0'}}</span>
        </template>
        <template>
          <span class="ser" :style="{ width:(100/serOverview_.length)+'%' }">{{serList ? serList['perCount'] : '0'}}</span>
        </template>
      </div>
    </div>
    <div class="main" v-else-if="title==='搜索匹配率'">
      <div>
        <template v-for="(item,index) in serOverview_">
          <span class="ser" :key="index" :style="{ width:(100/serOverview_.length)+'%',margin:'0px 0px 0px 10px' }">{{item}}<i class="el-icon-caret-right"></i></span>
        </template>
      </div>
      <div>
        <template>
          <span class="ser" :style="{ width:(100/serOverview_.length)+'%' }">{{serList ? serList['matchingCount'] : '0'}}</span>
        </template>
        <template>
          <span class="ser" :style="{ width:(100/serOverview_.length)+'%' }">{{serList ? serList['referenceCount'] : '0'}}</span>
        </template>
        <template>
          <span class="ser" :style="{ width:(100/serOverview_.length)+'%' }">{{serList ? serList['notMatchingCount'] : '0'}}</span>
        </template>
        <template>
          <span class="ser" :style="{ width:(100/serOverview_.length)+'%' }">{{serList ? serList['matchingRate'] : '0'}}</span>
        </template>
      </div>
    </div>
    <div class="main" v-else-if="title==='员工概况'">
      <div>
        <template v-for="(item,index) in empOverview">
          <span class="ser" :key="index" :style="{ width:(100/empOverview.length)+'%',margin:'0px 0px 0px 10px' }">{{item}}<i class="el-icon-caret-right"></i></span>
        </template>
      </div>
      <div>
        <template>
          <span class="ser" :style="{ width:(100/empOverview.length)+'%' }">{{employee ? employee['brandManager'] : '0'}}</span>
        </template>
        <template>
          <span class="ser" :style="{ width:(100/empOverview.length)+'%' }">{{employee ? employee['factoryManager'] : '0'}}</span>
        </template>
        <template>
          <span class="ser" :style="{ width:(100/empOverview.length)+'%' }">{{employee ? employee['inputEmployee'] : '0'}}</span>
        </template>
      </div>
    </div>
    <div class="main" v-else-if="title==='产品新增'">
      <div>
        <template v-for="(item,index) in proOverview2">
          <span class="ser" :key="index" :style="{ width:(100/proOverview2.length)+'%',margin:'0px 0px 0px 10px' }">{{item}}<i class="el-icon-caret-right"></i></span>
        </template>
      </div>
      <div>
        <template>
          <span class="ser" :style="{ width:(100/proOverview2.length)+'%' }">{{addList ? addList['today'] : '0'}}</span>
        </template>
        <template>
          <span class="ser" :style="{ width:(100/proOverview2.length)+'%' }">{{addList ? addList['yesterday'] : '0'}}</span>
        </template>
        <template>
          <span class="ser" :style="{ width:(100/proOverview2.length)+'%' }">{{addList ? addList['nowmonth'] : '0'}}</span>
        </template>
      </div>
    </div>
  </el-card>
</template>

<script>
import { queryAddCount } from "@/api/modules/record";
import { queryProduct, selDevOverview, selHisOverview, selectFactoryModular } from "@/api"
import { nameAndRole } from '@/api/modules/product';
// import PanThumb from '@/components/PanThumb'
// import Mallki from '@/components/TextHoverEffect/Mallki'

//首页--产品概览
const proOverview = ['产品总数', '生产产品数', '实验产品数', '过程产品数']
//首页--搜索概览
const serOverview = ['搜索次数', '匹配次数', '不匹配次数', '匹配率', '搜索人数', '人均次数']
//首页--设备概览
const devOverview = ['全部设备', '授权设备', '授权人数', '授权过期']

export default {
  // components: { PanThumb, Mallki },

  //   filters: {
  //     statusFilter(status) {
  //       const statusMap = {
  //         success: "success",
  //         pending: "danger",
  //       };
  //       return statusMap[status];
  //     },
  //   },
  props: {
    title: {
      type: String,
      default: "11",
    },
    width: {
      type: String,
      default: "100%",
    },
    height: {
      type: String,
      default: "350px",
    },
    autoResize: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      proList: null,
      devList: null,
      serList: null,
      employee:null,
      addList:null,
      // sysOrder: 1,
      proOverview: ['产品总数', '生产产品数', '实验产品数', '过程产品数'],
      proOverview1: ['产品总数', '生产产品数', '实验产品数'],
      proOverview2: ['今日新增', '昨日新增', '本月新增'],
      serOverview: ['搜索次数', '已处理', '未处理', '搜索人数', '人均次数'],
      serOverview_:['匹配', '可参考', '不匹配', '匹配率'],
      devOverview: ['全部设备', '授权设备', '授权人数', '授权过期'],
      empOverview: ['品牌管理员','工厂管理员','产品录入员'],
      statisticsData: {
        article_count: 1024,
        pageviews_count: 1024,
      },
    };
  },
  computed: {
    // ...mapGetters(["name", "avatar", "roles"]),
  },
  mounted() {
    const title = this.title
    switch (title) {
      case '产品概览':
        this.queryProduct()
        break;
      case '设备概览':
        this.selDevOverview()
        break;
      case '搜索概览':
        this.selHisOverview()
        break;
      case '搜索匹配率':
        this.selHisOverview()
        break;
      case '员工概况':
        this.selectFactoryModular()
        break;
      case '产品新增':
        this.queryAddCount()
        break;
      default:
        ''
    }
  },
  methods: {
    async nameAndRole() {
      const params = {}
      const { data } = await nameAndRole(params)
      if (data && data.code === 0 && (data.vo.sysOrder === "1" || data.vo.sysOrder === "3")) {
        this.proOverview = ['产品总数', '生产产品数', '实验产品数']
        delete this.proList.processProduct
      }
    },
    //首页--产品概览
    async queryProduct() {
      const { data } = await queryProduct()
      if (data && data.code === 0) {
        this.proList = data.product
        this.nameAndRole()
      }
    },
    //首页--设备概览
    async selDevOverview() {
      const { data } = await selDevOverview()
      if (data && data.code === 0) {
        this.devList = data.data
      }
    },
    //首页--搜索概览
    async selHisOverview() {
      const { data } = await selHisOverview()
      if (data && data.code === 0) {
        this.serList = data.data
        console.log(this.serList);
      }
    },
    //首页--员工概况
    async selectFactoryModular() {
      const params = {
        brandId: sessionStorage.getItem('BrandInfo'),
      }
      const { data } = await selectFactoryModular(params)
      if (data && data.code === 0) {
        this.employee = data.data.employee
      }
    },
    //首页--产品新增
    async queryAddCount() {
      const { data } = await queryAddCount()
      if (data && data.code === 0) {
        this.addList = data.data
      }
    },
  }
};
</script>

 <style lang="scss" >
// .box-card-component {
//   .el-card__header {
//     padding: 0px !important;
//   }
// }
//
</style>
<style lang="scss" scoped>
.main {
  div {
    display: flex;
    font-size: 14px;
    color: #666666;
    &:nth-child(2) {
      font-weight: bold;
      font-size: 24px;
      color: #000;
      margin-top: 15px;
    }
    span {
      display: block;
      position: relative;
      &.dev-center {
        text-align: center;
      }
    }
    .tri_right {
      &::before {
        content: "";
        width: 0px;
        height: 0px;
        border-top: 4px solid transparent;
        border-bottom: 4px solid transparent;
        border-left: 6px solid #666666;
        position: absolute;
        top: 4px;
        left: 75px;
      }
    }
    .pro{
      margin-left: 10px;
      text-align: center;
    }
    .pro-color{
      text-align: center;
    }
    .pro:nth-child(1) {
      &::before {
        left: 60px;
      }
    }
    .pro-color:nth-child(4) {
      color: #ff9f24;
    }
    .ser {
      text-align: center;
      &:nth-child(1),
      &:nth-child(2),
      &:nth-child(5),
      &:nth-child(6) {
        &::before {
          left: 60px;
        }
      }
      &:nth-child(4) {
        &::before {
          left: 45px;
        }
      }
    }
    .ser-color:nth-child(4) {
      color: #4ecb73;
    }
  }
}
// .box-card-component {
//   .box-card-header {
//     position: relative;
//     height: 220px;
//     img {
//       width: 100%;
//       height: 100%;
//       transition: all 0.2s linear;
//       &:hover {
//         transform: scale(1.1, 1.1);
//         filter: contrast(130%);
//       }
//     }
//   }
//   .mallki-text {
//     position: absolute;
//     top: 0px;
//     right: 0px;
//     font-size: 20px;
//     font-weight: bold;
//   }
//   .panThumb {
//     z-index: 100;
//     height: 70px !important;
//     width: 70px !important;
//     position: absolute !important;
//     top: -45px;
//     left: 0px;
//     border: 5px solid #ffffff;
//     background-color: #fff;
//     margin: auto;
//     box-shadow: none !important;
//     ::v-deep .pan-info {
//       box-shadow: none !important;
//     }
//   }
//   .progress-item {
//     margin-bottom: 10px;
//     font-size: 14px;
//   }
//   @media only screen and (max-width: 1510px) {
//     .mallki-text {
//       display: none;
//     }
//   }
// }
</style>
