<template>
  <div :class="className" :style="{ height: height, width: width }" />
</template>

<script>
// import echarts from "echarts";
// require("echarts/theme/macarons"); // echarts theme
import * as echarts from "echarts";
import "echarts-gl";
import resize from "./mixins/resize";

const animationDuration = 3000;
import { findSearchCountTrend } from '@/api'

export default {
  mixins: [resize],
  props: {
    className: {
      type: String,
      default: "chart",
    },
    width: {
      type: String,
      default: "100%",
    },
    height: {
      type: String,
      default: "122px",
    },
    tData: {
      type: String,
      default: ''
    },
    bgColor: {
      type: Array,
      required: true,
      // default: [],
    },
  },
  data() {
    return {
      chart: null,
      list: []
    };
  },
  mounted() {
    if (!this.chart) {
      this.findSearchCountTrend().then(() => {
        this.chart = null
        this.$nextTick(() => {
          this.initChart();
          this.refreshData()
        });
      })
    } else {
      this.refreshData()
    }

  },
  beforeDestroy() {
    if (!this.chart) {
      return;
    }
    this.chart.dispose();
    this.chart = null;
  },
  methods: {
    refreshData() {
      //刷新数据
      let option = this.chart.getOption();
      if (this.tData === 'searchCountData') {
        option.series[0].data = this.list.map(item => item.searchCount);
      } else if (this.tData === 'searchAccuracyRateData') {
        option.series[0].data = this.list.map(item => item.searchAccuracyRate);
      }

      this.chart.setOption(option);
    },
    initChart() {
      const _this = this;

      this.chart = echarts.init(this.$el, "macarons");


      let options = {
        backgroundColor: "", //背景颜色透明
        tooltip: {
          trigger: "axis", //鼠标经过提示
          axisPointer: {
            type: "none",
          },
          // formatter: function (a) {
          //   console.log(a)
          // }
          formatter: function (a) {
            // console.log(a)
            var res = "";
            var nameList = a;
            for (var i = 0; i < a.length; i++) {
              if (nameList[i].data != undefined) {
                res +=
                  nameList[i].axisValue +
                  ":" +
                  nameList[i].data +
                  (_this.tData === "searchAccuracyRateData" ? "%" : "") +
                  "<br>";
              }
            }
            res = res.split("<br>");
            return res[0] + "<br>" + res[1];
          },
        },
        legend: {
          icon: "roundRect", //icon为圆角矩形
          x: "center",
          y: "bottom",
          data: ["样例1", "样例2", "样例3"],
          textStyle: {
            //图例文字的样式
            color: "#fff",
            fontSize: 16,
          },
        },
        grid: {
          left: 0,
          top: "5%",
          left: 0,
          bottom: "5%",
          containLabel: true,
        },
        xAxis: {
          type: "category",
          data: _this.list.map(item => item.searchDate),//_this.tData === 'searchCountData' ? _this.searchCountData.map(item => item.label) : _this.searchAccuracyRateData.map(item => item.label),
          // () => {
          //   if (_this.tData === 'searchCountData') {
          //     return _this.searchCountData.map(item => item.label)
          //   }
          //   return _this.searchAccuracyRateData.map(item => item.label)
          // },//["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
          show: false, //隐藏x轴
        },
        yAxis: {
          type: "value",
          splitLine: { show: false }, //去除网格线
          show: false, //隐藏y轴
        },
        color: ["#fff"],
        series: [
          {
            name: "",
            type: "line",
            // symbol: "0",//////////
            showSymbol: false,
            itemStyle: {
              color: "#6A5ACD",
              normal: {
                lineStyle: {
                  // 系列级个性化折线样式
                  width: 2,
                  type: "solid",
                  color: _this.bgColor[0],
                },
              },
              emphasis: {
                color: _this.bgColor[0], //点的颜色
                lineStyle: {
                  // 系列级个性化折线样式
                  width: 3,
                  type: "dotted",
                  color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                    {
                      offset: 0,
                      color: "#1E90FF",
                    },
                    {
                      offset: 1,
                      color: "#0000FF",
                    },
                  ]),
                },
              },
            }, //线条样式
            areaStyle: {
              normal: {
                //颜色渐变函数 前四个参数分别表示四个位置依次为左、下、右、上
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  {
                    offset: 0,
                    color: _this.bgColor[0],
                  },
                  {
                    offset: 0.34,
                    color: _this.bgColor[1],
                  },
                  {
                    offset: 1,
                    color: _this.bgColor[2],
                  },
                ]),
              },
            }, //区域颜色渐变
            data: []//_this.tData === 'searchCountData' ? _this.searchCountData.map(item => item.name) : _this.searchAccuracyRateData.map(item => item.name),
            // () => {
            //   if (_this.tData === 'searchCountData') {
            //     return _this.searchCountData.map(item => item.name)
            //   }
            //   return _this.searchAccuracyRateData.map(item => item.name)
            // },//[450, 432, 401, 454, 590, 530, 110, 242],
          },
        ],
      };

      this.chart.setOption(options);
    },
    async findSearchCountTrend() {
      const _this = this
      this.searchCountData = null
      this.searchAccuracyRateData = null
      const { data } = await findSearchCountTrend()
      if (data && data.code === 0) {
        this.list = data.list
        if (this.tData === "searchCountData") {
          this.$emit('searchcount', data.list[data.list.length - 1].searchCount)
        }
        if (this.tData === "searchAccuracyRateData") {
          this.$emit('searchaccuracyrate', data.list[data.list.length - 1].searchAccuracyRate)
        }

        // this.searchCountData = data.list.map(item => {
        //   return {
        //     label: item.searchDate,
        //     name: item.searchCount
        //   }
        // })
        // this.searchAccuracyRateData = data.list.map(item => {
        //   return {
        //     label: item.searchDate,
        //     name: item.searchAccuracyRate
        //   }
        // })
      }
    }
  },
};
</script>
